import React, {useState} from "react";
import {UsersForm} from "./UserFrom/index";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import PeopleIcon from "@material-ui/icons/People";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import UserBalance from "./AccountBalance/index";
import {RolesForm} from "./RolesForm/index";
import {ValidationForm} from "./VerificationForm";
import {currentUrlParams} from 'PNH/services/Helpers';
import {SportLevelForm} from "./SportLevelForm";
import OpinionsTable from "./OpinionsTable";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CustomTabs from "components/CustomTabs/CustomTabs";
import {Api} from "PNH/services/Api";
import {BenefitForm} from "./BenefitForms";
import {OptionsForm} from "./OptionsForm";
import {ActivitiesProfileFilters} from "./ActivitiesSearch";
import UserAuditsTable from "./Audits/Table"
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import LockIcon from '@material-ui/icons/Lock';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BallotIcon from '@material-ui/icons/Ballot';
import ReceiptIcon from "@material-ui/icons/Receipt";
import QueueIcon from '@material-ui/icons/Queue';
import CommentIcon from '@material-ui/icons/Comment';
import SettingsIcon from '@material-ui/icons/Settings';
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import {FiltersForm} from "../BenefitEntries/Search";
import {ShoppingCart} from "./Basket";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from "../../../components/Badge/Badge";
import {LimitsForm} from "./LimitsForm";
import Accordion from "components/Accordion/Accordion";
import EventBusyIcon from '@material-ui/icons/EventBusy';
import {ChildrenForm} from "./Children";
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import {TransactionsTable} from "./Transactions/index";
import {Link} from "react-router-dom";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const url = () => currentUrlParams().urlPath;

class Profile extends React.Component {
    state = {
        user: {},
    };

    constructor(props) {
        super(props);
        this.fetch();
    }

    fetch = () => {
        Api.users.admin
            .show(currentUrlParams().urlPath[1])
            .then((response) => {
                if (response.ok) {
                    this.setState({user: response.data});
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
    };

    render() {
        const {classes} = this.props;
        const {user} = this.state
        return (
            <>
                <GridContainer>
                    <GridItem xs={12} md>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <PeopleIcon/>
                                </CardIcon>
                                <h3 className={classes.cardIconTitle}>
                                    {user.first_name} {user.last_name}
                                </h3>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={8}>
                                        Email: {user.email}
                                    </GridItem>
                                    <GridItem xs={4}>
                                        Telefon: {user.phone}
                                    </GridItem>
                                    <GridItem xs={8}>
                                        Miasto: {user.city}
                                    </GridItem>
                                    <GridItem xs={4}>
                                        Data ur.: {user.birth_date}
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <CardGiftcardIcon/>
                                </CardIcon>
                                <h3 className={classes.cardIconTitle}>
                                    Benefit
                                </h3>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        Nr Karty: {user.benefit_id}
                                    </GridItem>
                                    <GridItem xs={6}>
                                        Status: {user.benefit_active && user.benefit_id ? 'Aktywna' : 'Nieaktywna'}
                                    </GridItem>
                                    <GridItem xs={6}>
                                        Pierwsza linia: {user.first_line}
                                    </GridItem>
                                    <GridItem xs={6}>
                                        Druga linia: {user.second_line}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <CardGiftcardIcon/>
                                </CardIcon>
                                <h3 style={{fontSize: '24px'}} className={classes.cardIconTitle}>
                                    Fitprofit/Medicover
                                </h3>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        Nr Karty fitprofit: {user.fitprofit_id}
                                    </GridItem>
                                    <GridItem xs={6}>
                                        Status: {user.fitprofit_active && user.fitprofit_id ? 'Aktywna' : 'Nieaktywna'}
                                    </GridItem>
                                    <GridItem xs={12}>
                                        Medicover status: {user.ok_system_active ? 'Aktywna' : 'Nieaktywna'}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <AccountBalanceWalletIcon/>
                                </CardIcon>
                                <h3 className={classes.cardIconTitle}>{this.state.user.account_value} zł</h3>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        Główne: {this.state.user.account_balance} zł
                                    </GridItem>
                                    <GridItem xs={6}>
                                        Bonus: {this.state.user.account_bonus_value} zł
                                    </GridItem>
                                    <GridItem xs={6}>
                                        Stan Kaucji: {this.state.user.account_deposit_value} zł
                                    </GridItem>
                                    <GridItem xs={6}>
                                        Data ważności: {this.state.user.bonus_expiration_date}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    { user.parent_id &&  <GridItem xs={12} md>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <PeopleIcon/>
                                </CardIcon>
                                <h3 className={classes.cardIconTitle} >Rodzic</h3>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12}>
                                      <Link to={`/users/${user.parent_id}`} target={"_blank"}> {user.parent.first_name} {user.parent.last_name} <OpenInNewIcon style={{width: '15px', height: '15px'}}/></Link>
                                    </GridItem>
                                    <GridItem xs={8}>
                                        Email: {user.parent.email}
                                    </GridItem>
                                    <GridItem xs={4}>
                                        Telefon: {user.parent.phone}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem> }


                    <GridItem xs={12}><b>{user.notes}</b></GridItem>
                    {<TabsUser user={this.state.user} {...this.props} />}
                </GridContainer>
            </>
        );
    }
}

const TabsUser = ({classes, user, ...props}) => {
    return <CustomTabs
        headerColor="primary"
        tabs={[
            {
                tabName: 'Zapisy',
                tabIcon: SportsBasketballIcon,
                tabContent: <BoughtActivitiesTab classes={classes} {...props} />
            },
            {tabName: 'Profil', tabIcon: PeopleIcon, tabContent: <ProfilTab classes={classes} {...props} />},
            {tabName: 'Benefity', tabIcon: CardGiftcardIcon, tabContent: <BenefitTab classes={classes} {...props} />},
            {
                tabName: 'Portfel',
                tabIcon: AccountBalanceWalletIcon,
                tabContent: <BalanceTab classes={classes} {...props} />
            },
            {tabName: 'Uprawnienia', tabIcon: LockIcon, tabContent: <PermissionTab classes={classes} {...props} />},
            {tabName: 'Poziomy', tabIcon: TrendingUpIcon, tabContent: <SportLevelTab classes={classes} {...props} />},
            {tabName: 'Organizator', tabIcon: BallotIcon, tabContent: <OrganizerTab classes={classes} {...props} />},
            {tabName: 'Transakcje', tabIcon: ReceiptIcon, tabContent: <TransactionsTab classes={classes} {...props} />},
            {
                tabName: 'Dodane zajęcia',
                tabIcon: QueueIcon,
                tabContent: <AddedActivitiesTab classes={classes} {...props} />
            },
            {
                tabName: 'Dzieci',
                tabIcon: EmojiEmotionsIcon,
                tabContent: <ChildrenForm parent_id={url()[1]} classes={classes} {...props} />
            },
            {tabName: 'Oceny', tabIcon: CommentIcon, tabContent: <OpinionsTab classes={classes} {...props} />},
            {tabName: 'Audyt', tabIcon: LowPriorityIcon, tabContent: <AuditTab classes={classes} {...props} />},
            {
                tabName: user.basket_items_count > 0 ? <> Koszyk <Badge
                    color={"danger"}>{user.basket_items_count}</Badge> </> : "Koszyk",
                tabIcon: ShoppingCartIcon,
                tabContent: <ShoppingCart classes={classes} user_id={url()[1]} {...props} />
            },
            {tabName: 'Pozostałe', tabIcon: SettingsIcon, tabContent: <OptionsTab classes={classes} {...props} />},
            {tabName: 'Limity', tabIcon: EventBusyIcon, tabContent: <LimitsTab classes={classes} {...props} />},
        ]}
    />
}


const ProfilTab = ({classes, ...props}) => <UsersForm {...props} />
const PermissionTab = ({classes, ...props}) => <RolesForm {...props} />
const SportLevelTab = ({classes, ...props}) => <SportLevelForm userID={url()[1]}
                                                               setNotifications={window.setNotifications}  {...props} />
const OrganizerTab = ({classes, ...props}) => <ValidationForm userID={url()[1]}
                                                              setNotifications={window.setNotifications}/>
const TransactionsTab = ({classes, ...props}) => <TransactionsTable userID={url()[1]}/>
const AddedActivitiesTab = ({classes, ...props}) => <ActivitiesProfileFilters userID={url()[1]}/>
const OpinionsTab = ({classes, ...props}) => <OpinionsTable userID={url()[1]}/>
const BalanceTab = ({classes, ...props}) => <UserBalance classes={classes} {...props} />
const BenefitTab = ({classes, ...props}) => <GridContainer>
    <GridItem xs={12}>
        <BenefitForm classes={classes} {...props} />
    </GridItem>
    <GridItem xs={12}>
        <hr/>
    </GridItem>
    <GridItem xs={12} style={{marginTop: "40px"}}>
        <FiltersForm userID={url()[1]} {...props} />
    </GridItem>
</GridContainer>
const OptionsTab = ({classes, ...props}) => <OptionsForm classes={classes} {...props} />
const BoughtActivitiesTab = ({classes, ...props}) => <ActivitiesProfileFilters with_bought_by userID={url()[1]}/>
const AuditTab = ({classes, ...props}) => <UserAuditsTable userID={url()[1]}/>
const LimitsTab = ({classes, ...props}) => <LimitsForm classes={classes} {...props} />

export default withStyles(dashboardStyle)(Profile);
